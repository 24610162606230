$shade-color: #31b5d6;

.not-found {
    font-size: 16em;
    margin: auto;
    text-align: center;
    font-weight: bold;
    text-shadow:
        0 1px 0 #72c3d7,
        0 1px 2px #bed7dd,
        0 5px 0 #464646,
        0 6px 1px rgba($shade-color, .5),
        0 0 5px rgba($shade-color, .5),
        0 1px 2px rgba($shade-color, .6),
        0 3px 60px rgba($shade-color, .2);
}

.under {
    color: #72c3d7;
    font-weight: 100;
    letter-spacing: 0.5em;
}