$footer-bg: rgb(22 25 28);

footer {
    // background-color: hsl(from var(--bs-body-bg) h s calc(l + 50%));
    background-color: $footer-bg !important;
    position: absolute;
    bottom: 0;
    width: 100%;
    margin-top: 20px;
  }

.footer-link-icon {
    transition: all 0.2s ease-in-out;
    width: 32px;
    height: 32px;
    
    &:hover {
        transform: scale(1.1);
    }
}

.footer-icon {
    width: 32px;
    height: 32px;
}

