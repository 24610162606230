@import "../../colors.scss";

// Colors
$bgDefault: rgba(21, 22, 24, 0.8);
$bgHighlight: rgba(21, 22, 24, 0.8);
$colDefault: #ecf0f1;

$link-font-size: 1.6em;

// Navbar Styles
.navbar {
  background-color: transparent;
  border-color: $bgHighlight;
  z-index: 2;
  text-align: center;

  .navbar-brand {
    color: $colDefault;
    font-size: $link-font-size;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  .nav-link {
    color: $colDefault;
    font-size: $link-font-size;
    position: relative;
    transform: translateY(0%);
    transition: transform text-shadow 0.3s ease;

    &:hover,
    &:focus {
      color: $primary;
      transform: translateY(-5%);
      text-shadow: 0px 0px 40px $primary;
      &::after {
        transform: scaleX(1);
      }
    }

    &.active {
      color: $primary !important;
      transform: translateY(-5%);
    }

    $line-w: 90%;
    $offet: (
      100% - $line-w) * 0.5;

    &::after {
      content: "";
      position: absolute;
      background: linear-gradient(90deg, transparent, $primary, transparent
      );
    height: 1px;
    bottom: 10%;
    width: $line-w;
    left: $offet;
    /* Centering adjustment */
    transition: all 0.3s ease;
    transform: scaleX(0);
  }
}

.navbar-nav {
  >li>a {
    color: $colDefault;

    &:hover,
    &:focus {
      color: $primary;
    }
  }

  .active>a,
  .open>a {
    color: $primary;
    background-color: $bgHighlight;
  }

  >.dropdown>a {
    .caret {
      border-top-color: $colDefault;
      border-bottom-color: $colDefault;
    }

    &:hover,
    &:focus {
      .caret {
        border-top-color: $primary;
        border-bottom-color: $primary;
      }
    }
  }
}
}

.navbar-toggler {
  background: transparent !important;
  width: 42px;
  height: 42px;
  padding: 6px !important;
  margin: 8px;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}

.navbar-toggler .icon-bar {
  transition: all ease-in-out 0.2s;
  background-color: white;
  height: 2px;
  margin-top: 7px;
  margin-bottom: 7px;
  width: 100%;
  display: block;
}

$translate-y: 8.5px;

.navbar-toggler .icon-bar:nth-of-type(1) {
  transform: translateY($translate-y) rotate(45deg);
  margin: 0;
}

.navbar-toggler .icon-bar:nth-of-type(2) {
  opacity: 0;
  transform: rotate(-45deg);
}

.navbar-toggler .icon-bar:nth-of-type(3) {
  transform: translateY(-$translate-y) rotate(-45deg);
  margin: 0;
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(1) {
  transform: rotate(0);
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(2) {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .icon-bar:nth-of-type(3) {
  transform: rotate(0);
}

// Navbar Wrapper
.navbar-wrapper {
  position: relative;
}


// Navbar Wrapper Before Pseudo-Element
.navbar-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: inset 0px -2px 2px rgb(21, 22, 24);
  z-index: 1;
}

// Navbar Canvas
.navbar-canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.circular-image-container {
  width: 70px;
  /* Adjust the size of the circular container as needed */
  height: 70px;
  overflow: hidden;
  border-radius: 50%;
  border: 2px solid #333;
  background-color: #131313;
}

.circular-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Ensure the image covers the circular container */
  border-radius: 50%;
}