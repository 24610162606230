@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import 'colors';

/* 
Animation
*/
$animation_duration_short: 0.2s;
$animation_duration_medium: 0.3s;
$animation_duration_long: 0.5s;

.main-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1 0 auto;
}

.content-wrapper {
  margin-left: 5%;
  margin-right: 5%;
}

@media (min-width: 1920px) {
  .content-wrapper {
    margin-left: 25%;
    margin-right: 25%;
  }
}

@media (min-width: 1024px) {
  .content-wrapper {
    margin-left: 15%;
    margin-right: 15%;
  }
}

.heading {
  background-color: rgba(255, 255, 255, 0.1);
  margin: 0px;
}

.back-shadow {
  transition: all ease-in-out $animation_duration_short;
  box-shadow: 2px 2px 8px -4px $shadow-color;
}

.back-shadow:hover {
  box-shadow: 4px 4px 8px -4px $shadow-color;
}

@keyframes fade {
  0% {
    opacity: 0;
    left: 100%;
  }

  100% {
    opacity: 1;
    left: 0%;
  }
}

@import "~bootstrap/scss/bootstrap";

a {
  color: $primary; /* Define your link color */
  text-decoration: none;
  transition: color $animation_duration_short ease; /* Add a smooth color transition on hover */

  &:hover {
    color: lighten($primary, 30%); /* Change the link color on hover */
  }
}
