@import '../../colors';

$blur-radius: 24px;
$animation-duration: 3s;
$inset: 0.85;

@property --rotate {
    syntax: "<angle>";
    initial-value: 45deg;
    inherits: false;
}

@property --offset {
    syntax: "<percentage>";
    initial-value: 10%;
    inherits: false;
}

.card {
    background: linear-gradient(var(--rotate), #242d30, #24272c) !important;
    position: relative;
    display: flex;
}

.card::after {
    content: "";
    position: absolute;
    align-items: center;
    justify-content: center;
    top: -$blur-radius * $inset;
    left: -$blur-radius * $inset;
    right: -$blur-radius * $inset;
    bottom: -$blur-radius * $inset;
    z-index: -1;
    opacity: 0.25;
    // animation: spin $animation-duration linear infinite;
    padding: $blur-radius;
    box-shadow: inset 0 0 $blur-radius $blur-radius var(--bs-body-bg);
    background: linear-gradient(var(--rotate), var(--bs-primary), #4d48e4 43%, #30b0c9);
    transition: opacity 0.3s;
    transform: translateZ(0);
}

@keyframes spin {
    0% {
        --rotate: 0deg;
    }

    100% {
        --rotate: 360deg;
    }
}


@keyframes offset {
    0% {
        --offset: 0%;
    }

    100% {
        --offset: 100%;
    }
}