    .quoteText {
        text-align: center;
        position: relative;
        font-size: 1.6rem;
        font-weight: 200;
        line-height: 1.5;
        user-select: none;
        overflow: hidden;
        align-items: center;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .quoteText span {
        font-weight: 600;
    }